import React from 'react';
import Helmet from 'react-helmet';

const { GATSBY_SITEURL } = process.env;

const Dochead = ({
  title,
  siteName,
  pageUrl,
  description,
  pageImage,
  metaName,
  metaContent,
}) => (
  <Helmet
    title={title ? `${title} | ${siteName}` : `${siteName}`}
    meta={[
      { name: 'description', content: description },
      { property: 'og:description', content: description },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: title ? `${title} | ${siteName}` : `${siteName}`,
      },
      { property: 'og:site_name', content: siteName },
      {
        property: 'og:url',
        content: pageUrl ? GATSBY_SITEURL + pageUrl : GATSBY_SITEURL,
      },
      { property: 'og:image', content: pageImage },
      { property: 'og:image:secure_url', content: pageImage },
      { name: metaName, content: metaContent },
    ]}
    link={[]}
  >
    <html lang="en" />
  </Helmet>
);

export default Dochead;
